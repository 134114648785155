import React, { Fragment, useState } from "react";
import { Input, Button, styled, Text, Box, Label } from "@100mslive/react-ui";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({
  name,
  email,
  password,
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onJoin,
  DecodeUserJoined,
  enableJoin,
  ws,
  guestUserJoined,
  inProgress,
}) => {
  const [guestForm, setGuestForm] = useState(true);

  const formSubmit = e => {
    e.preventDefault();
  };

  return guestForm ? (
    <Box>
      <Text
        css={{
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
          color: "#ffffff",
          marginBottom: "20px",
        }}
      >
        Join as a Guest
      </Text>
      <Form onSubmit={formSubmit} css={{ alignItems: "baseline" }}>
        <Label css={{ color: "#ffffff" }}>Display Name</Label>
        <Input
          required
          id="name"
          css={{ w: "400px" }}
          value={name}
          onChange={e => onNameChange(e.target.value)}
          placeholder="Enter your name"
          autoFocus
          autoComplete="name"
        />
        <Label css={{ color: "#ffffff" }}>Email Id</Label>
        <Input
          required
          id="email"
          css={{ w: "400px" }}
          value={email}
          onChange={e => onEmailChange(e.target.value)}
          placeholder="Enter your email"
          autoComplete="email"
        />
        <Button
          type="submit"
          loading={inProgress}
          disabled={!name || !email || !enableJoin || inProgress}
          onClick={guestUserJoined}
          css={{
            w: "400px",
            background:
              "linear-gradient(90deg, rgb(100, 218, 161) 0%, rgb(0, 180, 228) 100%) 0% 0% no-repeat padding-box padding-box transparent",
          }}
        >
          {isStreamingKit() ? "Join Studio" : "Join a Meeting"}
        </Button>
      </Form>
      <Box>
        <Box
          css={{
            borderBottom: "1px dashed #ffffff",
            textAlign: "center",
            height: "10px",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              color: "#ffffff",
              padding: "0px 5px",
              background: "#00205b",
            }}
          >
            OR
          </span>
        </Box>
        <Text css={{ color: "#ffffff", mt: "22px" }}>
          Already have a Decode account?
        </Text>
        <Button
          type="submit"
          css={{
            w: "400px",
            background: "#ffffff",
            color: "#000000",
            mt: "16px",
          }}
          onClick={() => {
            setGuestForm(false);
            onNameChange(undefined);
          }}
        >
          Join as a Decode User
        </Button>
      </Box>
    </Box>
  ) : (
    <Fragment>
      <Box>
        <Text
          css={{
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#ffffff",
            marginBottom: "20px",
          }}
        >
          Join as a Decode User
        </Text>
        <Box css={{ padding: "20px 40px" }}>
          <Form onSubmit={formSubmit} css={{ alignItems: "baseline" }}>
            <Label css={{ color: "#ffffff" }}>Email Id</Label>
            <Input
              required
              id="email"
              css={{ w: "400px" }}
              value={email}
              onChange={e => onEmailChange(e.target.value)}
              placeholder="Enter your email"
              autoFocus
              autoComplete="email"
            />
            <Label css={{ color: "#ffffff" }}>Password</Label>
            <Input
              required
              type="password"
              id="password"
              css={{ w: "400px" }}
              value={password}
              onChange={e => onPasswordChange(e.target.value)}
              placeholder="Enter your Password"
              autoComplete="password"
              maxLength={50}
              minLength={8}
            />
            <Button
              type="submit"
              loading={inProgress}
              disabled={!email || !password || !enableJoin || inProgress}
              onClick={DecodeUserJoined}
              css={{
                w: "400px",
                background:
                  "linear-gradient(90deg, rgb(100, 218, 161) 0%, rgb(0, 180, 228) 100%) 0% 0% no-repeat padding-box padding-box transparent",
              }}
            >
              {isStreamingKit() ? "Join Studio" : "Join a Meeting"}
            </Button>
          </Form>
        </Box>
      </Box>
      <Box>
        <Text css={{ color: "#ffffff", mt: "22px" }}>
          Don’t have a Decode account?
        </Text>
        <Button
          type="submit"
          css={{
            w: "400px",
            background: "#ffffff",
            color: "#000000",
            mt: "16px",
          }}
          onClick={() => setGuestForm(true)}
        >
          Join as a Guest User
        </Button>
      </Box>
    </Fragment>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
