import React from "react";
import { styled, flexCenter } from "@100mslive/react-ui";
import PreviewJoin from "./PreviewJoin";

const PreviewContainer = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  initialEmail,
  ws,
  meeting_id,
  wsId,
  dc_code,
  dc_region,
  setAuthToken,
}) => {
  return (
    <Container>
      <PreviewJoin
        initialName={initialName}
        initialEmail={initialEmail}
        skipPreview={skipPreview}
        env={env}
        onJoin={onJoin}
        token={token}
        ws={ws}
        meeting_id={meeting_id}
        wsId={wsId}
        dc_code={dc_code}
        dc_region={dc_region}
        setAuthToken={setAuthToken}
      />
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "column",
});

export default PreviewContainer;
