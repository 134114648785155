import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { Box, Flex, styled } from "@100mslive/react-ui";
import { Header } from "./Header";
import { ErrorDialog } from "../primitives/DialogContent";
import { useSetUiSettings, useTokenEndpoint } from "./AppData/useUISettings";
import PreviewContainer from "./Preview/PreviewContainer";
import SidePane from "../layouts/SidePane";
import { useNavigation } from "./hooks/useNavigation";
import {
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  QUERY_PARAM_NAME,
  QUERY_PARAM_SKIP_PREVIEW,
  UI_SETTINGS,
  QUERY_PARAM_EMAIL,
} from "../common/constants";

/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */

const env = "prod"; //process.env.REACT_APP_ENV;
const PreviewScreen = React.memo(
  ({ getUserToken, ws, meeting_id, wsId, dc_code, dc_region }) => {
    const navigate = useNavigation();
    const tokenEndpoint = useTokenEndpoint();
    const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
    const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
    const [token, setToken] = useState(null);
    const [error, setError] = useState({ title: "", body: "" });
    // way to skip preview for automated tests, beam recording and streaming
    const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
    let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
    // use this field to join directly for quick testing while in local
    const directJoinHeadfulFromEnv =
      process.env.REACT_APP_HEADLESS_JOIN === "true";
    const directJoinHeadful =
      useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
      directJoinHeadfulFromEnv;
    skipPreview = skipPreview || beamInToken || directJoinHeadful;
    const initialName =
      useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? "Beam" : "");
    const initialEmail =
      useSearchParam(QUERY_PARAM_EMAIL) || (skipPreview ? "Beam" : "");
    // let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);
    let authToken = "";

    useEffect(() => {
      if (authToken) {
        setToken(authToken);
        return;
      }
      if (!tokenEndpoint || !urlRoomId) {
        return;
      }
    }, [tokenEndpoint, urlRoomId, getUserToken, userRole, authToken]);

    const setAuthToken = authToken => {
      console.log("authToken", authToken);
      setToken(authToken);
    };

    useEffect(() => {
      console.log("updated token", token);
    }, [token]);

    const onJoin = () => {
      !directJoinHeadful && setIsHeadless(skipPreview);
      let meetingURL = `/meeting/${urlRoomId}`;
      if (userRole) {
        meetingURL += `/${userRole}`;
      }
      console.log("meetingURL", meetingURL);
      navigate(meetingURL);
    };

    if (error.title) {
      return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
    }
    return (
      <Flex
        direction="column"
        css={{ size: "100%", position: "relative", backgroundColor: "#00205B" }}
      >
        <Box
          css={{
            h: "$18",
            "@md": { h: "$17", flexShrink: 0 },
            position: "absolute",
            w: "100%",
          }}
          data-testid="header"
        >
          <Header isPreview={true} />
        </Box>
        <Flex
          css={{ flex: "1 1 0", position: "relative", overflowY: "auto" }}
          justify="center"
          align="center"
        >
          <PreviewContainer
            initialName={initialName}
            initialEmail={initialEmail}
            skipPreview={skipPreview}
            env={env}
            onJoin={onJoin}
            token={token}
            ws={ws}
            meeting_id={meeting_id}
            wsId={wsId}
            dc_code={dc_code}
            dc_region={dc_region}
            setAuthToken={setAuthToken}
          />
          <SidePane
            css={{
              position: "unset",
              mr: "$10",
              "@lg": { position: "fixed", mr: "$0" },
            }}
          />
        </Flex>
      </Flex>
    );
  }
);

const Link = styled("a", {
  color: "#2f80e1",
});

export const ErrorWithSupportLink = errorMessage => (
  <div>
    {errorMessage} If you think this is a mistake on our side, please create{" "}
    <Link
      target="_blank"
      href="https://github.com/100mslive/100ms-web/issues"
      rel="noreferrer"
    >
      an issue
    </Link>{" "}
    or reach out over{" "}
    <Link
      target="_blank"
      href="https://discord.com/invite/kGdmszyzq2"
      rel="noreferrer"
    >
      Discord
    </Link>
    .
  </div>
);

export default PreviewScreen;
